import React, { useState } from "react"
import loadable from "@loadable/component"
import { makeStyles } from "@material-ui/core/styles"
import "../../css/typography-muli.css"
const ChatComponent = loadable(() =>
  import("../../components/prod/custom-page")
)

const useStyles = makeStyles({
  circle: {
    position: "absolute",
    height: "385px",
    width: "489px",
    bottom: 0,
    right: "2%",
  },
  dots: {
    position: "absolute",
    left: "10%",
    top: "100px",
  },
})

function Chat() {
  // doing this loadable import so createStore works
  // https://stackoverflow.com/questions/65398447/webpackerror-typeerror-object-is-not-a-function-gatsbyjs-build-error-bot
  const classes = useStyles()
  // TODO: set
  const origDynamicStyleOptions = {
    hideSendBox: false,
  }
  const [hideSendBox, setHideSendBox] = useState(
    origDynamicStyleOptions.hideSendBox
  )

  function onHandleRestart() {
    setHideSendBox(origDynamicStyleOptions.hideSendBox)
  }

  return (
    <ChatComponent
      name="[regio iT] Bonn - Bonn.de"
      pageTitle="Convaise - Bonn - Bonn.de"
      // homepage="#"
      token="eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NzUxNTk1NDkuMjYwOTcyLCJleHAiOjIzMDU4Nzk1NDkuMjYwOTcyLCJib3RJZCI6ImRkeTE1MnE4bDM4bjJ0emEiLCJpc3MiOiJjb252YWlzZSIsImF1ZCI6ImNvbnZhaXNlLWNvdXJpZXIifQ.Hsz8pIYVWOoK-mI8cu1FpaZVYJeOwzbd9PH17OtBCtTvwJx3rhE3_5psO048-XZh1HIeAS5loHVPzLC_wxgM2llxRlM6t7QYbDjKzd6BmMoMgpiJ_vkVaSl_9x2uywlUbv9uBuC61hIqwQE-88qAAzAN6I6hDcd3M2sjtlCqEO7X3fklI7Nt3VWz1pOWEKY0yB-oabLg1pdGISGki9t8R91q4ZID5DvN5FspOEzFKiusq6i9cF3ooqn7FmMBcmxCrgGvsrk2LTaKYMsXLsD9wP_eg7_HPZe0wICTDB_oqYH5D1ekA0_w7WRswAqA95V_xPK5dwdTYgPeNSDvs9jJlDAB9YNKG4tAtBKRilYRpQGk6tYvfkDXXPjH-c2SNkO3puRm3Pd3Q1frY8P-lq2QraCi2vL8pt_wdrX-d7lKQk6beiuQlsKIvjd5IKlqJaUiZPkcnVM6jf__Je41DypqP7j0JYJsCpPFMl-4qA6QhGRzIinoQ-H1UpnprqhUHm0iO-bz-4szvFLnRJfOvxJFbcNg9ShqbL5l2wEPrh_ziQ_-5weT4L3-U-56PPKQdxlwSzLIMwv2SWpje-iEX965jgpoJoplJGD8N7rKc2HGdQ9v3NcWnjJbBp-vBKRpQY27fACrUc0I92xKQzgu8u4c4n2y7SQ7CaZLYbDXK6XfDjg"
      botId="ddy152q8l38n2tza"
      avatar={false}
      avatarInitials={false}
      logo="https://service.bonn.de/documents/33843/0/BON_Logo_Stadt_blau_00008b-.png"
      domain="https://courier.convaise.com/frontend/v3/directline"
      origDynamicStyleOptions
      onHandleRestart={onHandleRestart}
      styleOptions={{
        primaryFont: "'Noto Sans', 'sans-serif'",
        bubbleFromUserTextColor: "white",
        bubbleTextColor: "#293754",
        bubbleFromUserBackground: "#134d88",
        botAvatarBackgroundColor: "#fff",
        hideSendBox,
        // primaryFont:
        //   '"Open Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
      }}
      styleCustomizations={{
        "--button-blue": "#134d88",
        "--button-blue-light": "#5a82ac",
        // "--button-red": "rgb(120, 125, 141)",
        // "--button-red-light": "rgba(120, 125, 141, 0.8)",
      }}
      onHideSendBox={value => {
        // console.log("changing hideSendbox")
        setHideSendBox(value)
      }}
      overrideLocalizedStrings={{
        TEXT_INPUT_PLACEHOLDER: "Ihre Frage...",
      }}
      backgroundColor="#F0F4F5"
      customs={[
        <img
          src="https://static.convaise.com/demos/gegenbauer/gegenbauer-circle.svg"
          data-src="https://static.convaise.com/demos/gegenbauer/gegenbauer-circle.svg"
          className={classes.circle}
          role="presentation"
          alt=""
          key="customization-circle"
        />,
        <img
          src="https://static.convaise.com/demos/gegenbauer/gegenbauer-dots.svg"
          data-src="https://static.convaise.com/demos/gegenbauer/gegenbauer-dots.svg"
          className={classes.dots}
          key="customization-dots"
          role="presentation"
          alt=""
        />,
      ]}
      // css="https://static.convaise.com/webchat/gegenbauer/convaise-assistant-gegenbauer-iframe-min.css"
    />
  )
}

export default Chat
